.highlight-on-hover:hover {
  background-color: #eee;
}

table.payment_detalis th{
  font-size: 12px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-align: center;
}
.payment_detalis{
  width: 100%;
}
table.payment_detalis td{
  text-align: center;
  font-size: 12px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 10px;
}

td.Query_detlis{
  width: 500px;
}
table.Query_detalis th{
  font-size: 14px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-align: center;
}
table.Query_detalis td{
  text-align: center;
  font-size: 13px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 10px;
}

table.Query_detalis{
  width: 100%;
}

.---View_more--{
  display: block;
  margin-top: 3px;
  color: #007bff;
  font-size: 12px;
  font-weight: 700;
}
.---View_more-- P{
  margin-bottom: 0;
}

.User_payment_details p{
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 500;
}
.User_payment_details_two p{
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 600;
}
.User_payment_details_two{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 0;
}
.static_data_payment{
  /* padding: 20px; */
  /* display: flex; */
  flex-wrap: wrap;
}
.catagary_by_data_payment ul, ol{
  margin-bottom: 0;
}
.User_payment_details{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.catagary_by_data_payment h3{
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid rgb(223, 223, 223);
  background: rgb(108, 77, 169);
  padding: 10px 20px;
  color: #fff;
}
.catagary_by_data_payment{
  /* width: 50%; */
  /* background: rgb(248, 248, 248); */
  /* padding: 20px; */
  margin-bottom: 30px;

}
.User_payment_details span{
  text-align: left;
  display: block;
  font-size: 16px;
  font-weight: 500;
}
.User_payment_details_two span{
  font-size: 16px;
  font-weight: 600;
}
.coures_plan_details{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px 10px 0;
}
.coures_plan_details p{
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}
.coures_plan_details span{
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}

.User_payment_details_three{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.User_payment_details_three p{
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
}
.User_payment_details_three span{
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
}

.failed{
  background-color: #ef3038;
  color: #fff;
}
.success{
  background-color: #28a745;
  color: #fff;
}


/* radio btn bool question style  */
.radio_btn {
  --s: 1em;     /* control the size */
  --c: #009688; /* the active color */
  
  height: var(--s);
  aspect-ratio: 1;
  border: calc(var(--s)/8) solid #939393;
  padding: 3px;
  background: 
     radial-gradient(farthest-side,var(--c) 94%,#0000) 
     50%/0 0 no-repeat content-box;
  border-radius: 50%;
  outline-offset: calc(var(--s)/10);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: inherit;
  transition: .3s;
  font-size: xx-large;
}
.radio_btn:checked {
  border-color: var(--c);
  background-size: 100% 100%;
}



@media print {
  .radio_btn {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
    background: none;
  }
}

.radio_label {
  display:inline-flex;
  align-items:center;
  gap:10px;
  margin:5px 0;
  cursor: pointer;
}

.radio_parent{
  display: flex;
  justify-content: space-between;
  width: 40%;
}

.status_success{
  width: 100%;
  background: #28a745;
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding:1rem 0;
}
.status_failed{
  width: 100%;
  background: red;
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding:1rem 0;
}

.Iamge_delete_copy_button {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
/* #Image_div:hover .Iamge_delete_copy_button{
  opacity: 1;
}
.Iamge_delete_copy_button button{
  height: 16%; 
}
#Image_div:hover .image_sec {
  opacity: 0.2;
  background: #000;
} */
#Image_div{
  width: 15rem;
  border: 1px solid rgb(160, 158, 158);
  /* padding: 0.2rem; */
}
.image_sec{
  width: 100%;
	display: block;
	aspect-ratio: 1 / 1;
	object-fit: fill;
	transition: transform 1000ms;
}

.galleryUL{
	list-style: none;
	margin: 0;
	padding: 0;
	display: grid;
	gap: 0.5rem;
	grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
	max-width: 100%;
	/* width: 70rem; */

}

figure {
	margin: 0;
	position: relative;
	overflow: hidden;
}

figure::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 200%;
	height: 200%;
	background: rgba(0, 0, 0, 0.5);
	transform-origin: center;
	opacity: 0;
	transform: scale(2);
	transition: opacity 300ms;
}

figcaption {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	place-items: center;
	text-align: center;
	padding: 1rem;
	color: white;
	font-size: 1.2rem;
	z-index: 1;
	opacity: 0;
	transition: opacity 600ms, transform 600ms;
}

#Image_div:hover .Iamge_delete_copy_button{
  opacity: 1;
}
#Image_div:is(:hover, :focus) figure::after {
	opacity: 1;
}

#Image_div:is(:hover, :focus) figcaption {
	opacity: 1;
	transition: opacity 600ms;
}

@media (prefers-reduced-motion: no-preference) {
	figcaption {
		transform: translate3d(0, 2rem, 0);
	}
	
	figure::after {
		border-radius: 50%;
		opacity: 1;
		transform: scale(0);
		transition: transform 900ms;
	}
	
	#Image_div:is(:hover, :focus) figure::after {
		transform: scale(2.5);
	}

	#Image_div:is(:hover, :focus) figcaption {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		transition: opacity 600ms 400ms, transform 600ms 400ms;
	}

	#Image_div:is(:hover, :focus) img {
		transform: scale(1.2);
	}
}

.model_diloage_div_certificate{
  max-width: 60vw;
}
.model_diloage_div_certificate_div{
  height: 90vh;

}